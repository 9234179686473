/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react";
import Amplify from "aws-amplify";
import CartState from "./src/components/shopping-cart/providers/CartState";
import { toast } from "react-toastify";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "./src/styles/react-toastify.scss";

const ToastCloseButton = ({ closeToast }) => (
  <i className="fa fa-times" onClick={closeToast} />
);

toast.configure({
  autoClose: 3500,
  draggable: true,
  closeButton: <ToastCloseButton />,
});

const amplifyConfig = {
  Auth: {
    userPoolId: process.env.GATSBY_AMPLIFY_AUTH_USER_USER_POOL_ID,
    region: process.env.GATSBY_AMPLIFY_AUTH_REGION,
    userPoolWebClientId:
      process.env.GATSBY_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: process.env.GATSBY_AMPLIFY_AUTH_IDENTITY_POOL_ID,
    mandatorySignIn: process.env.GATSBY_AMPLIFY_AUTH_MANDATORY_SIGN_IN,
    authenticationFlowType:
      process.env.GATSBY_AMPLIFY_AUTH_AUTHENTICATION_FLOW_TYPE,
  },
};
try {
  Amplify.configure(amplifyConfig);
} catch (e) {
  console.log("Error configuring amplify");
  console.log(e);
}

const wrapRootElement = ({ element }) => <CartState>{element}</CartState>;

export { wrapRootElement };
