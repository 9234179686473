/* eslint-disable no-console */
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const UPDATE_CART_ITEM_QUANTITY = 'UPDATE_CART_ITEM_QUANTITY'

export const addToCart = product => dispatch => {
  console.log(ADD_TO_CART)
  console.log(product)
  dispatch({
    product,
    type: ADD_TO_CART,
  })
}

export const removeFromCart = product => dispatch => {
  dispatch({
    product,
    type: REMOVE_FROM_CART,
  })
}

export const clearCart = () => dispatch => {
  dispatch({
    type: CLEAR_CART,
  })
}

export const updateCartItemQuantity = (product, quantity) => dispatch => {
  dispatch({
    product,
    quantity,
    type: UPDATE_CART_ITEM_QUANTITY,
  })
}
