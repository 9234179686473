import React from 'react'
import PropTypes from 'prop-types'
import ShoppingCartContext from '../shopping-cart-context'
import shoppingCartReducer from '../reducer'
// import getCurrentSiteCountryCode from '../../../utils/getCurrentSiteCountryCode'
import {
  ADD_TO_CART,
  CLEAR_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_ITEM_QUANTITY,
} from '../actions'
import INITIAL_STATE from '../initial-state'

const CartState = ({ children }) => {
  const [{ cart }, dispatch] = React.useReducer(
    shoppingCartReducer,
    INITIAL_STATE
  )

  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('CART STATE UPDATED')
    // eslint-disable-next-line no-console
    // console.log(JSON.stringify(cart))
    localStorage.setItem('cart', JSON.stringify(cart))
    // console.log(localStorage)
  })

  return (
    <ShoppingCartContext.Provider
      value={{
        cart,
        addToCart: product => dispatch({ product, type: ADD_TO_CART }),
        removeFromCart: product =>
          dispatch({ product, type: REMOVE_FROM_CART }),
        clearCart: () =>
          dispatch({
            type: CLEAR_CART,
          }),
        updateCartItemQuantity: (product, quantity) =>
          dispatch({
            product,
            quantity,
            type: UPDATE_CART_ITEM_QUANTITY,
          }),
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  )
}

CartState.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CartState
