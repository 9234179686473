import {
  ADD_TO_CART,
  CLEAR_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_ITEM_QUANTITY,
} from "./actions";

export default (state, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      const { product } = action;
      console.log("product", product);
      let alreadyExistedInCart = false;
      let updatedCart = state.cart.map((cartItem) => {
        if (cartItem.product.parentId === product.parentId) {
          alreadyExistedInCart = true;
          return {
            product,
            quantity: cartItem.quantity + 1,
          };
        }

        return cartItem;
      });

      if (!alreadyExistedInCart) {
        updatedCart = [
          ...state.cart,
          {
            product,
            quantity: 1,
          },
        ];
      }

      return {
        ...state,
        cart: updatedCart,
      };
    }

    case REMOVE_FROM_CART: {
      const updatedCart = state.cart.filter(
        (cartItemPotential) =>
          cartItemPotential.product.parentId !== action.product.parentId
      );
      return {
        ...state,
        cart: updatedCart,
      };
    }

    case UPDATE_CART_ITEM_QUANTITY: {
      const { product, quantity } = action;
      const updatedCart = state.cart.map((cartItem) => {
        if (cartItem.product.parentId === product.parentId) {
          return {
            product,
            quantity: Number(quantity),
          };
        }

        return cartItem;
      });

      return {
        ...state,
        cart: updatedCart,
      };
    }

    case CLEAR_CART: {
      return {
        ...state,
        cart: [],
      };
    }

    default:
      return state;
  }
};
