// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coming-soon-js": () => import("/opt/build/repo/src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-error-index-js": () => import("/opt/build/repo/src/pages/error/index.js" /* webpackChunkName: "component---src-pages-error-index-js" */),
  "component---src-pages-forgot-my-password-index-js": () => import("/opt/build/repo/src/pages/forgot-my-password/index.js" /* webpackChunkName: "component---src-pages-forgot-my-password-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-index-js": () => import("/opt/build/repo/src/pages/join/index.js" /* webpackChunkName: "component---src-pages-join-index-js" */),
  "component---src-pages-join-login-form-js": () => import("/opt/build/repo/src/pages/join/loginForm.js" /* webpackChunkName: "component---src-pages-join-login-form-js" */),
  "component---src-pages-join-register-form-js": () => import("/opt/build/repo/src/pages/join/registerForm.js" /* webpackChunkName: "component---src-pages-join-register-form-js" */),
  "component---src-pages-login-index-js": () => import("/opt/build/repo/src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-manage-subscription-index-js": () => import("/opt/build/repo/src/pages/manage-subscription/index.js" /* webpackChunkName: "component---src-pages-manage-subscription-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("/opt/build/repo/src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-reset-password-index-js": () => import("/opt/build/repo/src/pages/reset-password/index.js" /* webpackChunkName: "component---src-pages-reset-password-index-js" */),
  "component---src-pages-subscription-index-js": () => import("/opt/build/repo/src/pages/subscription/index.js" /* webpackChunkName: "component---src-pages-subscription-index-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("/opt/build/repo/src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-pages-thank-you-index-js": () => import("/opt/build/repo/src/pages/thank-you/index.js" /* webpackChunkName: "component---src-pages-thank-you-index-js" */)
}

